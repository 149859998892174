import "../../styles/signup/signup.scss";
import BaseButton from "wallet-elementkit/dist/elementKit/baseButton/BaseButton";

import { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
	Link,
	useHistory,
	useLocation,
} from "react-router-dom/cjs/react-router-dom";
import BaseLink from "../../elementKit/baseLink/BaseLink";
import BaseInput from "../../elementKit/baseInput/BaseInput";
import Recaptcha from "../../elementKit/recaptcha/Recaptcha";
import { Translate, withLocalize } from "react-localize-redux";
import translations from "../../translations/signup.json";
import Notification from "../../components/notification/Notification";
import FixedInfoFooter from "../../components/fixedInfoFooter/FixedInfoFooter";
import ExternalAccountItems from "../../components/externalAccountItems/ExternalAccountItems";
import { EmailRegex } from "../../constants/Enums";
import { signupLoginOtp } from "wallet-elementkit/dist/services/backend/User";
import { errorValueConverter } from "../../services/utils/error";
import { useAppDispatch } from "../../store/hooks";
import { userActions } from "../../store/features/user";
import { getMasterConfig } from "wallet-elementkit/dist/constants/master";
import { OptimizedMixPanel } from "wallet-elementkit/dist/services";
import mixpanel from "mixpanel-browser";
import { Subdomains } from "wallet-elementkit/dist/constants";

const Signup = (props) => {
	useLayoutEffect(() => {
		props.addTranslation(translations);
	}, []);
	const [isSignupActive, setIsSignupActive] = useState(false);
	const [email, setEmail] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [loading, setLoading] = useState(false);
	const recaptchaRef = useRef(null);
	const [validation, setValidation] = useState(true);

	const location = useLocation();
	const history = useHistory();
	const dispatch = useAppDispatch();
	const config = getMasterConfig();
	const disableExternalAuth = config.subDomain === Subdomains.bitdefender;
	const optimizedMixpanel = new OptimizedMixPanel(mixpanel);

	useEffect(() => {
		const stateData = location.state;
		if (stateData?.fromLogin) {
			setEmail(stateData.fromLogin?.email || "");
			Notification({
				type: "error",
				text: stateData.fromLogin?.notifText,
			});
			setIsSignupActive(true);
		}
	}, [location]);

	const checkRequirements = async (token) => {
		if (
			firstName.length < 3 ||
			lastName.length < 3 ||
			!email.match(EmailRegex) ||
			!token
		) {
			setValidation(false);
			return false;
		}
		return true;
	};

	const signup = async () => {
		setValidation(true);
		const token = await recaptchaRef.current?.executeAsync();
		let valid = await checkRequirements(token || "");
		if (!valid) return;

		setLoading(true);
		const otpBody = {
			email: email,
			firstName: firstName,
			lastName: lastName,
			reCaptchaToken: token || "",
		};
		const userData = await signupLoginOtp(
			otpBody,
			"signup",
			getMasterConfig().subDomain
		);
		if (userData !== null && userData.success) {
			dispatch(userActions.setUserId(userData.userId));
			optimizedMixpanel.track("Signup", {
				email: email,
				firstName: firstName,
				lastName: lastName,
				isLogged: false,
			});
			history.push({
				pathname: `/${props.activeLanguage?.code ?? "en"}/verify-code`,
				search: `?email=${email}`,
				state: {
					status: userData.status,
				},
			});
		} else {
			if (userData) {
				Notification({
					type: "error",
					text: props.translate(
						errorValueConverter(userData?.error || "error")
					),
				});
			} else {
				Notification({
					type: "error",
					text: props.translate("error"),
				});
			}
			setLoading(false);
		}
	};

	return (
		<div
			id="signup-main"
			style={{
				justifyContent: isSignupActive ? "flex-start" : "center",
			}}
		>
			{isSignupActive ? (
				<div className="signup-container">
					<span className="container-title">
						<Translate
							id="signupToOrgName"
							data={{
								organizationName: config.organizationName,
							}}
						/>
					</span>
					<span
						className="container-desc"
						style={{
							marginTop: "18px",
							marginBottom: "30px",
						}}
					>
						<Translate
							id="signupDescTextWithOrgName"
							data={{
								organizationName: config.organizationName,
							}}
						/>
					</span>
					<div className="flex-column field-container">
						<span className="field-title">
							<Translate id="firstName" />
						</span>
						<BaseInput
							width={337}
							height={39}
							placeholder={"Jane"}
							value={firstName}
							onChangeAction={setFirstName}
							isErrorActive={!validation && firstName.length < 3}
							errorText={props.translate("nameError").toString()}
						/>
					</div>
					<div className="flex-column field-container">
						<span className="field-title">
							<Translate id="lastName" />
						</span>
						<BaseInput
							width={337}
							height={39}
							placeholder={"Doe"}
							value={lastName}
							onChangeAction={setLastName}
							isErrorActive={!validation && lastName.length < 3}
							errorText={props.translate("lastNameError").toString()}
						/>
					</div>
					<div
						className="flex-column field-container"
						style={{
							marginBottom: "5px",
						}}
					>
						<span className="field-title">
							<Translate id="emailAddress" />
						</span>
						<BaseInput
							width={337}
							height={39}
							placeholder={
								config.subDomain === Subdomains.bitdefender
									? ""
									: process.env.REACT_APP_AUTH_PLACEHOLDER_EMAIL
							}
							value={email}
							onChangeAction={setEmail}
							isErrorActive={!validation && !email.match(EmailRegex)}
							errorText={props.translate("emailError").toString()}
						/>
					</div>
					<Recaptcha ref={recaptchaRef} />
					<BaseButton
						className="send-verif-code-btn"
						type="primary"
						size="large"
						width={337}
						onClickAction={() => {
							signup();
						}}
						loading={loading}
						height={39}
					>
						<span>
							<Translate id="sendVerificationCode" />
						</span>
					</BaseButton>

					<span
						className="have-account-login-text"
						style={{
							marginTop: "30px",
						}}
					>
						<Translate id="alreadyGotAnAccount" />{" "}
						<Link
							to={
								props.activeLanguage
									? `/${props.activeLanguage.code}/login/`
									: `/en/login/`
							}
						>
							<BaseLink
								href=""
								text={props.translate("login").toString()}
								title="Login Page"
							/>
						</Link>
					</span>
				</div>
			) : (
				<div className="signup-landing-container">
					<span className="container-title">
						<Translate
							id="signupToOrgName"
							data={{
								organizationName: config.organizationName,
							}}
						/>
					</span>
					<span className="container-desc">
						<Translate
							id="signupDescTextWithOrgName"
							data={{
								organizationName: config.organizationName,
							}}
						/>
					</span>
					<BaseButton
						className="signup-via-email-btn"
						type="primary"
						size="small"
						onClickAction={() => {
							setIsSignupActive(true);
						}}
						height={39}
					>
						<span className="">
							<Translate id="signUpWithEmail" />
						</span>
					</BaseButton>
					{!disableExternalAuth && (
						<>
							<span className="or">
								<Translate id="or" />
							</span>
							<div className="share-options flex-row jc-sb">
								<ExternalAccountItems setLoading={(v) => setLoading(v)} />
							</div>
						</>
					)}
					<span
						className="dont-have-account-text"
						style={
							disableExternalAuth
								? {
										marginTop: "20px",
								  }
								: undefined
						}
					>
						<Translate id="alreadyGotAnAccount" />{" "}
						<Link
							to={
								props.activeLanguage
									? `/${props.activeLanguage.code}/login/`
									: `/en/login/`
							}
						>
							{" "}
							<BaseLink
								href=""
								text={props.translate("login").toString()}
								title="Login Page"
							/>
						</Link>
					</span>
				</div>
			)}
			{isSignupActive && (
				<FixedInfoFooter
					title={props.translate("provideAValidEmailAddress")}
					desc={props.translate("provideDescText").toString()}
				/>
			)}
		</div>
	);
};

export default withLocalize(Signup);
