import { useRef, forwardRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import "../../styles/components/recaptcha.scss";

const Recaptcha = forwardRef((props, ref) => {
	const recaptchaRef = useRef(null);
	const recaptchaKey = window.location.origin.match(/localhost/g)
		? "6LftCPAqAAAAAGAsg7Bji1WFnNYqo9PIA77tY5gV"
		: process.env.REACT_APP_RECAPTCHA_KEY;

	return (
		<ReCAPTCHA
			id="recaptcha"
			ref={ref || recaptchaRef}
			sitekey={recaptchaKey}
			size="invisible"
			style={{ ...props.styles }}
		/>
	);
});

export default Recaptcha;
