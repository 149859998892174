import { Translate, withLocalize } from "react-localize-redux";
import "../../styles/login/login.scss";
import BaseButton from "wallet-elementkit/dist/elementKit/baseButton/BaseButton";

import { useLayoutEffect, useRef, useState } from "react";
import translations from "../../translations/signup.json";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom";
import BaseLink from "../../elementKit/baseLink/BaseLink";
import BaseInput from "../../elementKit/baseInput/BaseInput";
import Recaptcha from "../../elementKit/recaptcha/Recaptcha";
import { signupLoginOtp } from "wallet-elementkit/dist/services/backend/User";
import ExternalAccountItems from "../../components/externalAccountItems/ExternalAccountItems";
import { useAppDispatch } from "../../store/hooks";
import { userActions } from "../../store/features/user";
import Notification from "../../components/notification/Notification";
import { getMasterConfig } from "wallet-elementkit/dist/constants/master";
import mixpanel from "mixpanel-browser";
import { OptimizedMixPanel } from "wallet-elementkit/dist/services";
import { Subdomains } from "wallet-elementkit/dist/constants";

const Login = (props) => {
	useLayoutEffect(() => {
		props.addTranslation(translations);
	}, []);

	const dispatch = useAppDispatch();
	const optimizedMixpanel = new OptimizedMixPanel(mixpanel);
	const [isLoginActive, setIsLoginActive] = useState(false);
	const [email, setEmail] = useState("");
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);
	const recaptchaRef = useRef(null);

	const history = useHistory();

	const config = getMasterConfig();
	const disableExternalAuth = config.subDomain === Subdomains.bitdefender;

	const login = async () => {
		recaptchaRef.current?.reset();
		const token = await recaptchaRef.current?.executeAsync();
		setError(false);
		if (!email || !token) {
			setError(true);
			return;
		}
		setLoading(true);
		const otpBody = {
			email: email,
			reCaptchaToken: token || "",
		};
		const data = await signupLoginOtp(
			otpBody,
			"login",
			getMasterConfig().subDomain
		);
		if (data != null && data.success) {
			dispatch(userActions.setUserId(data.userId));
			optimizedMixpanel.track("Login", {
				method: "email",
				email: email,
			});
			history.push({
				pathname: `/${props.activeLanguage?.code ?? "en"}/verify-code`,
				search: `?email=${email}`,
				state: {
					status: "login",
				},
			});
		} else {
			if (data.error === "pleaseEnterAllFieldsToRegister.") {
				history.push({
					pathname: `/${props.activeLanguage?.code ?? "en"}/signup`,
					state: {
						fromLogin: {
							email: email,
							notifText: props.translate("noUserSignUp").toString(),
						},
					},
				});
				return;
			} else {
				recaptchaRef.current?.reset();
				if (data) {
					Notification({
						type: "error",
						text: props.translate(data.error),
					});
				} else {
					Notification({
						type: "error",
						text: props.translate("error"),
					});
				}
				setLoading(false);
			}
		}
		setLoading(false);
	};
	return (
		<div id="login-main">
			{isLoginActive ? (
				<div className="login-container">
					<span className="container-title">
						<Translate
							id="loginToOrgName"
							data={{
								organizationName: config.organizationName,
							}}
						/>
					</span>
					<span
						className="container-desc"
						style={{
							marginTop: "18px",
							marginBottom: "30px",
						}}
					>
						<Translate id="loginActiveHelperText" />
					</span>
					<BaseInput
						width={337}
						height={39}
						placeholder={
							config.subDomain === Subdomains.bitdefender
								? ""
								: process.env.REACT_APP_AUTH_PLACEHOLDER_EMAIL
						}
						value={email}
						onChangeAction={setEmail}
						isErrorActive={error && email.length < 3}
						errorText=""
					/>
					<Recaptcha ref={recaptchaRef} />
					<BaseButton
						className="send-verif-code-btn"
						type="primary"
						size="large"
						width={337}
						onClickAction={login}
						loading={loading}
						height={39}
					>
						<span>
							<Translate id="sendVerificationCode" />
						</span>
					</BaseButton>
					<span
						className="have-account-login-text"
						style={{
							marginTop: "30px",
						}}
					>
						<Translate id="alreadyGotAnAccount" />{" "}
						<Link
							to={
								props.activeLanguage
									? `/${props.activeLanguage.code}/signup/`
									: `/en/signup/`
							}
						>
							<BaseLink
								href=""
								text={props.translate("login").toString()}
								title="Login Page"
							/>
						</Link>
					</span>
				</div>
			) : (
				<div className="login-landing-container">
					<span className="container-title">
						<Translate
							id="loginToOrgName"
							data={{
								organizationName: config.organizationName,
							}}
						/>
					</span>
					<span className="container-desc">
						<Translate
							id="loginDescTextWithOrgName"
							data={{
								organizationName: config.organizationName,
							}}
						/>
					</span>
					<BaseButton
						className="login-via-email-btn"
						type="primary"
						size="small"
						onClickAction={() => {
							setIsLoginActive(true);
						}}
						height={39}
					>
						<span className="">
							<Translate id="loginWithEmail" />
						</span>
					</BaseButton>

					{!disableExternalAuth && (
						<>
							<span className="or">
								<Translate id="or" />
							</span>
							<div className="share-options flex-row jc-sb">
								<ExternalAccountItems setLoading={(v) => setLoading(v)} />
							</div>
						</>
					)}
					<span
						className="dont-have-account-text"
						style={
							disableExternalAuth
								? {
										marginTop: "20px",
								  }
								: undefined
						}
					>
						<Translate id="dontHaveAccount" />{" "}
						<Link
							to={
								props.activeLanguage
									? `/${props.activeLanguage.code}/signup/`
									: `/en/signup/`
							}
						>
							{" "}
							<BaseLink
								href=""
								text={props.translate("signUp").toString()}
								title="Login Page"
							/>
						</Link>
					</span>
				</div>
			)}
		</div>
	);
};

export default withLocalize(Login);
